.modal-bg {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgb(from var(--mpac-concrete) r g b /50%);
    z-index: 1060;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.modal-container {
    border-radius: 0.3125em;
    background: var(--mpac-background);
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding: 0.25rem;
    position: relative;
    text-align: left;
    word-wrap: break-word;
    width: fit-content;
    height: fit-content;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable both-edges;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid var(--mpac-concrete);
    /* padding-top: 0.5em;
    padding-bottom: 0.5em; */
    /* padding-left: 1em;
    padding-right: 0.5em; */
    /* margin-bottom: 1.25em; */
}

.modal-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    /* overflow-y: hidden !important; */
    /* padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.0em;
    padding-right: 0.5em; */
    /* margin-bottom: 1.25em; */
}

.modal-title {
    color: var(--mpac-blue);
    font-family: Quicksand, sans-serif;
    font-weight: bold;
    padding: 0 1rem;
}

/* .modal-container .btn-grp button {
    margin-left: 5px;
} */

.modal-header>p {
    margin: 0;
}

.modal-content {
    padding: 0.5rem 1rem;
}

.btn-grp {
    border-top: 1px solid var(--mpac-concrete);
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.no-border {
    border: 0 !important;
}

.close-btn button {
    background: none;
    border: none;
    box-shadow: none;
}

.close-btn button:hover,
.close-btn button:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.close-btn svg {
    font-size: 1.5em;
}

.close-btn svg:hover {
    color: var(--mpac-safetyorange);
}

.confirm-dialog-max-width {
    max-width: 30em !important;
  }
  