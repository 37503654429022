:root {
  --tenantName-min-width: 25vw;
  --floorLevel-min-width: 10vw;
}

.toast-info {
  background: #cce8ff;
  color: #000;
  /* background: var(--mpac-main-100); 
  color: #fff; */
}

.toast-success {
  background: #ddeedf;
  color: #000;
  /* background: var(--mpac-green); 
  color: #fff; */
}

.toast-warning {
  background: #fff7cc;
  color: #000;
  /* background: var(--mpac-yellow); 
  color: #fff; */
}

.toast-error {
  background: #f9d2d2;
  color: #000;
  /* background: var(--mpac-red); 
  color: #fff; */
}

.unit-table-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  max-width: 100%;
}

.disclaimer p {
  margin: 0;
}

.unit-table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.unit-table-text {
  margin: 0;
}

.unit-table-control-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unit-table-control-group button {
  border: none;
  border-radius: 0;
  background-color: var(--mpac-main-20);
  color: black;
  font-size: 1em;
  margin: 0.15em;
  padding: 0.5em 1em;
}

.popover-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px var(--mpac-main-30);
  font-size: 0.875rem;
  min-width: 10rem;
  padding: 1rem;
  z-index: 9;
}

.filter-popover .popover-content {
  position: absolute;
  margin-top: 0rem;
}

@media screen and (max-width: 1024px) {
  .filter-popover .popover-content {
    left: 68%
  }
}

@media screen and (min-width: 1440px) {
  .filter-popover .popover-content {
    left: 77%
  }
}

@media screen and (min-width: 2560px) {
  .filter-popover .popover-content {
    left: 60%
  }
}
.filter-popper-column {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  text-wrap-mode: nowrap;
}

.tenant-search-input {
  width: 14em;
  margin: 0;
}

.no-wrap {
  text-wrap: nowrap;
}

.filter-table td {
  padding: 2px;
}

.lease-info-commercial {
  display: flex;
  margin: 20px 0;
}

.lease-info-commercial p {
  margin: 0 30px 0 0;
}

.lease-info-commercial #commercial-yes {
  margin-right: 1.25rem;
  width: 0.875rem;
  height: 0.875rem;
}

/* @media screen and (max-width: 822px) {
  .unit-table-subcontainer {
    width: 40px;
  }
} */

.unit-table-summary-wrapper {
  width: 100%;
}

/* @media screen and (max-width: 1024px) {
  .unit-table-container {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .unit-table-container {
    width: 100%;
  }
} */

.highlighted-mandatory-cell {
  background: var(--mpac-yellow) !important;
}

.tenant-summary-table-container {
  overflow-x: auto;
  width: fit-content;
  max-width: 100%;
}

.tenant-summary-table {
  --table-row-hover-color: var(--mpac-teal-50-100);

  border-spacing: 0;
}

.tenant-summary-table th,
.tenant-summary-table td {
  padding: 0 0.5rem;
  height: 2em;
}

.tenant-summary-table th {
  text-wrap: nowrap;
  text-align: center;
}

.tenant-summary-table td {
  background-color: var(--mpac-background);
}

.tenant-summary-table tbody>tr:hover td {
  background-color: var(--table-row-hover-color);
}

.tenant-summary-table .edit-btn,
.tenant-summary-table .delete-btn {
  background: transparent;
  border: none;
  font-size: 1rem;
}

.tenant-summary-table .edit-btn {
  color: black;
}

.tenant-summary-table tr>*:first-child {
  position: sticky;
  left: 0;
}

.tenant-summary-table tr>*:last-child {
  position: sticky;
}

.tenantName-min-width {
  min-width: var(--tenantName-min-width);
}

.floorLevel-min-width {
  min-width: var(--floorLevel-min-width);
}

.tenant-summary-table .edit-btn-off,
.tenant-summary-table .delete-btn-off {
  background: transparent;
  border: none;
  padding: 0px 4px;
  cursor: not-allowed;
}

.tenant-summary-table .edit-btn-off {
  color: rgb(170, 170, 170);
  padding-right: 12px;
}

.tenant-summary-table .delete-btn-off {
  color: var(--mpac-red) !important;
}