.sidebar-item-medium-container {
    width: 100%;
}

.sidebar-status-container {
    min-width: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mpac-green);
    font-size: large;
}

.sidebar-medium-item {
    display: flex;
    padding: 0.35rem 0;
    border-radius: 0.3rem;
}

.sidebar-medium-item-text {
    margin: 0.25rem 0.15rem;
}

.sidebar-medium-item-active {
    font-weight: bold;
    background-color: var(--mpac-main-30);
}

.sidebar-medium-item-inactive {
    cursor: pointer;
}

.sidebar-item-small-root-container {
    background-color: white;
    border-radius: 0 0 5px 5px;
}