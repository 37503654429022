.datepicker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.datepicker-container {
    position: relative;
}

.datepicker-container .react-datepicker__year-wrapper, .datepicker-container .react-datepicker__month-wrapper {
    justify-content: space-between;
}

.datepicker-container .react-datepicker__year-wrapper>div, .datepicker-container .react-datepicker__month-wrapper>div {
    width: 3rem;
    padding: 0.5em 0;
}

.datepicker-container .react-datepicker__year, .datepicker-container .react-datepicker__monthPicker {
    width: 10rem;
}

.datepicker-container .react-datepicker__children-container {
    width: auto;
    text-align: center;
}

.datepicker-container .datepicker-back-button {
    padding: 0.5em 0.75em;
}

.datepicker-input {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    width: 100% !important;
    box-sizing: border-box !important;
    margin: 0px !important;
}

.datepicker-icon {
    position: absolute;
    /* margin-left: -25px;
    margin-top: 7px; */
    color: var(--mpac-blue);
    top: 0.35rem;
    right: 0.7rem;
}

@media screen and (min-width: 2560px) {
    .datepicker-icon {
        top: 0.6rem;
        right: 0.7rem;
    }
}

.react-datepicker-popper {
    z-index: 100000 !important;
}

pre {
    text-wrap: wrap;
}