header {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.autosave-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.switch-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.switch-block p {
  margin-right: 0.5rem;
}

.autosave-block {
  margin-right: 0.25rem;
}

.last-saved-text {
  margin: -0.3em 0 0 0;
  font-size: smaller;
}

.internal-user-tag {
  font-size: large;
  color: var(--mpac-main-100);
  font-weight: bold;
  margin: 0.5rem;
}

.header-links-first-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: flex-end;
}

.header-links-second-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.header-text {
  margin: 0 1rem;
}

.translation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-access-item {
  height: 25px;
}

.lang-access-item img {
  height: 25px;
}

.roll-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 0.5rem;
}

.roll-address-container>.roll-address-info {
  margin: 0 !important;
}