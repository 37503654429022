.footer-root {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  vertical-align: middle;
  border-top: 0.35px solid var(--mpac-main-20);
  height: 3em;
  padding: 0.5em 0em;
}

@media screen and (max-width: 700px) {
  .footer-root {
    flex-direction: column;
    height: 6em;
  }
}
.footer-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 2;
}

.footer-text {
  margin: 0 1rem;
}

.footer-button-holder {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  right: 0;
  margin-right: 1rem;
}

.all-rights-reserved>.anchor-to-button:hover {
  text-shadow: none;
  cursor: auto;
  box-shadow: none;
  color: var(--mpac-light-grey);
  outline: none;
}

@media screen and (min-width: 2560px) {
  .footer-root {
    border-top: 1.3px solid var(--mpac-main-20);
  }
}

.footer-logout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 8em;
}

.footer-help-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 3.75rem;
}

.footer-image-container img {
  width: 75%;
  height: auto;
  min-width: 4.6875rem;
  max-width: 100%;
}

.footer-image-container {
  width: 4.6875rem;
  /* min-width: calc(75px + 20px); */
  align-content: center;
}

.footer-aoda-root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-aoda-text {
  display: flex;
  color: var(--mpac-light-grey);
  align-items: center;
  width: 5rem;
}

.footer-language-container {
  display: flex;
  color: var(--mpac-light-grey);
  align-items: center;
  width: 3.125rem;
}

.footer-aoda-root>*:not(:last-child) {
  border-right: 0.0625rem var(--mpac-light-grey-border) solid;
  padding-right: 1rem;
}

.footer-aoda-root>*:not(:first-child) {
  padding-left: 1rem;
}

@media screen and (max-width: 1200px) {
  .footer-aoda-root>*:not(:last-child) {
    border-right: 1px var(--mpac-light-grey-border) solid;
    padding-right: 0.5rem;
  }

  .footer-aoda-root>*:not(:first-child) {
    padding-left: 0.5rem;
  }
}

.footer-aoda-root p {
  margin: 0;
}

.footer-root p {
  margin: 0;
}

.footer-link {
  text-decoration: none;
  color: var(--mpac-light-grey);
}

.footer-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 720px) {
  .footer-button-holder {
    position: relative;
    margin: 0.5rem 0;
  }
}