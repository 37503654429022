.start-body-container {
    width: 100%;
    align-content: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 26em);
}

.start-body {
    display: flex;
    justify-content: center;
}

.start-heading-section {
    max-width: fit-content;
    text-align: center;
}

.start-footer {
    margin-top: 0.5em;
}
.campaign-list-wrapper {
    width: fit-content;
    padding: 1em;
    align-self: center;
}

.import-block {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rems;
}

.import-block button {
    margin: 0;
}

.start-page-return-btn {
    align-self: center;
}