.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    min-width: fit-content;
    height: fit-content;
    min-height: 100%;
}

.sidebar-raw-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-gutter: stable;
}

.sidebar-collapsed {
    width: 5vw;
    min-width: 75px;
}

.sidebar-collapse-container {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
}

.sidebar-collapse-btn {
    cursor: pointer;
}

/* 
.sidebar-main-section {
    width: 100%;
    max-width: 100%;
} */

.sidebar-year {
    color: var(--mpac-main-100);
    cursor: pointer;
    font-weight: bold;
    justify-content: center;
    display: flex;
    vertical-align: middle;
    margin: 1.5em 0;
}

.sidebar-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
}

.sidebar-footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    min-width: 220px;
}

.sidebar-footer-info-container {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    min-width: 15rem;
}

.sidebar-logo-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px var(--mpac-light-grey-border) solid;
    align-items: center;
}

.sidebar-logo-image-container img {
    width: 4rem;
}

@media screen and (min-width: 2560px) {
    .sidebar-logo-image-container img {
        width: 5rem;
    }
}

.sidebar-mpac-info {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sidebar-mpac-info div {
    margin: 0;
    font-size: 0.7rem;
}

.sidebar-mpac-info a {
    margin: 0;
    font-size: 0.7rem;
}

.sidebar-mpac-info-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}