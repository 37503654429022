.sidebar-large-item {

    display: flex;
    justify-content: flex-start;
    padding: 0.65rem 0.25rem;
    border-radius: 0.3rem;
}

.sidebar-large-item-text {
    margin: 0 0 0 1rem;
    font-size: 0.875rem;
}

@media screen and (min-width: 1440px) {
    .sidebar-large-item-text {
        margin: 0 0 0 1.2rem;
        font-size: 0.9rem;
    }
}

.sidebar-large-item-active {
    background-color: var(--mpac-main-100);
    color: white;
    font-weight: bold;
}

.sidebar-large-item-inactive {
    cursor: pointer;
}

.sidebar-item-large-container {
    width: 100%;
}