.contact-info-header p {
    margin-bottom: 0;
}
.contact-form .input-error-message{
    margin-top: 0;
    font-size: 12px;
    
}
.contact-info-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px 15px;
}
.contact-info-table label{
    font-weight: normal;
    margin-bottom: 0;
    white-space: nowrap;
}

@media screen and (min-width: 2560px) {
    .contact-info-table input[type="radio"] {
        width: 0.8rem;
        height: 0.8rem;
    }
}


.contact-fiscal-year-end, .contact-partial-year {
    width: 8.75rem !important;
    border-radius: 0.25rem;
}
.contact-analysis-label{
    margin-right: 10px;
}

.contact-partial-year:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))
}
.contact-owner-agent-container {
    margin-bottom: 8px;
}
.radio-error input:before{
    display: block;
    content: "";
    width: 10px;
    left: 0;
    height: 10px;
    border-radius: 50%;
    border: 2px solid var(--mpac-darker-red);
}
.side-by-side {
    display: flex;
    vertical-align: middle;
}
/* .side-by-side>span:nth-child(2) {
    margin-left: 20px !important;
} */

@media screen and (max-width: 1024px) {
    .side-by-side {
        display: block;
    }
}

.telephone-field .input-error-message {
    position: absolute;
    overflow: hidden;
}
.contact-full-phone-container {
    display: flex;
    gap: 1em;
}
.contact-phone-container {
    width: 60%;
    min-width: 90px;
}

@media screen and (max-width: 1024px) {
    .contact-phone-container {
        width: 100%;
        min-width: 90px;
    }
    
}
.contact-ext-container {
    width: 30%;
}
.contact-phone {
    width: 100% !important; 
    box-sizing: border-box;
    border-radius: 4px;
}
.contact-ext {
    border-radius: 4px;
    width: 100% !important; 
    box-sizing: border-box;
}

.contact-email, .contact-name {
    width: 60% !important;
    border-radius: 4px;
}

.top-aligned-row td {
    vertical-align: top;
}

.reviewed-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background-color: var(--pier-admin-background);
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 5px;
}

.reviewed-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem
}

.reviewed-check-container {
    display: flex;
}