.continue-body-container {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 26em);
}

.continue-body {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-align: center;
    max-width: fit-contents;
    display: flex;
    flex-direction: column;
    align-items: center;
}